.image_slider {
    text-align: center;
    margin-top: 30px;
}

.flex_row {
    justify-content: center;
    display: flex;
    padding-top: 10px;
}

.image_slider_thumbnail {
    padding-left: 5px;
}

.image_show {
    width: 100%;
    object-fit: contain;

    height: 600px;
}

.clicked {
    border: 5px solid black;
}